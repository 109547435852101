import { z, ZodIssueCode } from 'zod'

// due to a bug in GTM we are forced to explicitly set the whole set of data objects to either `object` or `undefined`
// to prevent previous values from bleeding into subsequent events in the datalayer
export const transformEventPayload = (data: Record<string, unknown>) => ({
  ...data,
  event: data.event,
  ecommerce: data.ecommerce,
  application_data: data.application_data,
  basket_data: data.basket_data,
  content_data: data.content_data,
  discount_data: data.discount_data,
  error_data: data.error_data,
  event_data: data.event_data,
  filter_data: data.filter_data,
  product_data: data.product_data,
  reminder_data: data.reminder_data,
  results_data: data.results_data,
  screen_data: data.screen_data,
  user_data: data.user_data,
})

const zodString = z.string().max(500)
export const zodBoolean = z.boolean()
export const zodNumber = z.number().min(0)

export const zodHyphenatedString = zodString.regex(/^[a-z0-9 -]+$/, {
  message:
    'Value must be lowercase. Only hyphens are allowed as special characters',
})

export const zodResultsListString = zodString.regex(/^[a-z0-9\- ]*$/, {
  message:
    'Value must be lowercase and only `-` is allowed as a special character.',
})

const zodFilterDataString = zodString.regex(/^[a-z0-9\-|&'? ]+$/, {
  message:
    "Value must be lowercase and only `-|&'?` special characters are allowed.",
})

export const zodSearchSuggestionEventLabelString = zodString.regex(
  /^[a-z0-9/| ]+$/,
  {
    message:
      'Value must be lowercase and only `/|` are allowed as special characters',
  },
)

export const zodScreenNameString = zodString.regex(/[a-z0-9?,!|\- ]+/, {
  message:
    'Value must be lowercase. Only `?,!|-` special characters are allowed.',
})

export const zodProductCategoryString = zodString.superRefine((val, ctx) => {
  if (!/^[a-z0-9_\-| ]+$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'Value must be lowercase and only `_-\\` are allowed as special characters',
      fatal: true,
    })

    return z.NEVER
  }

  if (!/^[^|]+( \| [^|]+)*$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'Value must contain ` | ` as a delimiter between values, with no trailing delimiter',
      fatal: true,
    })

    return z.NEVER
  }

  return true
})

export const zodProductNameString = zodString.regex(/^[a-z0-9?!' ]+$/, {
  message: "Value must be lowercase. Only `?!'` special characters are allowed",
})

export const zodLowerCaseString = zodString
  .regex(/^[a-z0-9 ]+$/, {
    message:
      'Value must be lowercase and should not contain special characters',
  })
  .refine(v => v.trim().length > 0, {
    message: 'Value cannot hold only spaces',
  })

const zodCurrencyEnum = z.enum(['AUD', 'GBP', 'EUR', 'USD'])
export const zodLoginMethodEnum = z.enum([
  'email',
  'google',
  'apple',
  'google one tap',
  'password',
  'magic link',
])

export const zodCustomerTypeEnum = z.enum([
  'prospect',
  'new customer',
  'existing customer',
])

export const zodResultsDataSuggestionTypeEnum = z.enum([
  'navigational',
  'trending',
  'popular',
])

export const zodEmailString = zodString.regex(/^[a-z0-9]+$/, {
  message:
    '`email` field value should be hashed and cannot contain any special characters!',
})

const zodDelimitedLowerCaseString = zodString.superRefine((val, ctx) => {
  if (!/^[a-z0-9| ]+$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'Value must be lowercase and only ` | ` is allowed as a delimiter',
      fatal: true,
    })

    return z.NEVER
  }

  if (!/^[^|]+( \| [^|]+)*$/.test(val)) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message:
        'Value must contain ` | ` as a delimiter between values, with no trailing delimiter',
      fatal: true,
    })

    return z.NEVER
  }

  return true
})

export const zodAddressCountString = zodDelimitedLowerCaseString.refine(
  /* istanbul ignore next */ data => {
    return /^(\d+ ?\| ?\d+)$/.test(data)
  },
  {
    message:
      '`address_count` should follow "<no. of send to me addresses> | <no. of send to them addresses>" pattern',
  },
)

export const zodUpdateIntiatorEnum = z.enum([
  'delete',
  'duplicate',
  'quantity',
  'size',
  'add to cart',
])

export const zodResultsDataInputTypeEnum = z.enum([
  'facet suggestion',
  'keyword',
  'filters menu',
])

export const eventDataSchema = z.object({
  action: zodLowerCaseString,
  category: zodLowerCaseString,
  label: zodString,
  non_interaction: z.literal(true).optional(),
  value: zodNumber.int().optional(),
})

export const applicationDataSchema = z.object({
  datalayer_version: zodLowerCaseString,
})

export const basketDataSchema = z.object({
  id: zodHyphenatedString,
  size: zodNumber.optional(),
  update_initiator: zodUpdateIntiatorEnum.optional(),
})

export const contentDataSchema = z.object({
  content_type: zodString.regex(/[a-z0-9&‘\-| ]+/, {
    message:
      'Value must be lowercase. Only `&‘|-` special characters are allowed',
  }),
  item_id: zodString.regex(/[^A-Z]/).optional(),
})

export const errorDataSchema = z.object({
  id: zodLowerCaseString.or(zodNumber.int()).optional(),
  message: zodString,
})

export const filterDataSchema = z.object({
  filter_name: zodFilterDataString.optional(),
  show_rude_products: zodBoolean.optional(),
  number_of_active_filters: zodNumber.int().optional(),
  last_filter_interaction: zodFilterDataString.optional(),
})

export const productDataSchema = z
  .object({
    bundle_id: zodString.regex(/^[a-z0-9|\- ]+$/, {
      message:
        '`bundle_id` must be lowercase and only allows `|` and `-` special characters',
    }),
    design_id: zodHyphenatedString,
    group_card_id: zodLowerCaseString,
    product_brand: zodLowerCaseString,
    product_category: zodProductCategoryString,
    product_id: zodLowerCaseString,
    product_orientation: zodLowerCaseString,
    product_name: zodProductNameString,
    product_price: zodNumber,
    product_quantity: zodNumber.int(),
    product_variant: zodLowerCaseString,
    project_id: zodLowerCaseString,
  })
  .partial()

export const remindersDataSchema = z
  .object({
    days_until: zodNumber.int(),
    filter: zodLowerCaseString,
    origin: zodLowerCaseString,
    relation: zodLowerCaseString,
  })
  .partial()
  .extend({
    index: zodString.regex(/\d+\/\d+/, {
      message: '`index` should follow `<number>/<number>` pattern',
    }),
    occasion: zodLowerCaseString,
  })

export const resultsDataSchema = z.object({
  corrected_search_term: zodLowerCaseString.optional(),
  index: zodNumber.int().optional(),
  input_type: zodResultsDataInputTypeEnum,
  number_of_results: zodNumber.int(),
  product_category: zodProductCategoryString,
  results_list: zodResultsListString,
  sort_by: z.enum(['newness', 'popularity']).optional(),
  suggestion_type: zodResultsDataSuggestionTypeEnum.optional(),
})

export const screenDataSchema = z.object({
  document_referrer: z.union([zodString.url(), z.literal('')]).optional(),
  document_title: zodScreenNameString.optional(),
  document_url: zodString.url().optional(),
  render_type: z.enum(['client', 'server']),
})

export const userDataSchema = z
  .object({
    address_count: zodAddressCountString,
    customer_id: zodHyphenatedString,
    customer_type: zodCustomerTypeEnum,
    email: zodEmailString,
    is_logged_in: zodBoolean,
    lifetime_order_count: zodNumber,
    login_method: zodLoginMethodEnum,
  })
  .partial()
  .extend({
    is_logged_in: zodBoolean,
  })

export const discountDataSchema = z
  .object({
    name: zodLowerCaseString,
    type: zodLowerCaseString.optional(),
    value: zodNumber.optional(),
    currency: zodCurrencyEnum.optional(),
  })
  .refine(
    /* istanbul ignore next */ data => {
      const bothPresent =
        data.currency !== undefined && data.value !== undefined
      const bothAbsent = data.currency === undefined && data.value === undefined

      return bothPresent || bothAbsent
    },
    {
      message:
        'Both value and currency must be either both present or both absent',
      path: ['currency', 'value'],
    },
  )

export const ecommerceItemSchema = z
  .object({
    coupon: zodLowerCaseString,
    currency: zodCurrencyEnum,
    discount: zodNumber,
    index: zodNumber.int(),
    is_sponsored: z.enum(['true', 'false', 'internal']),
    item_brand: zodLowerCaseString,
    item_category: zodHyphenatedString,
    item_category2: zodString.regex(/[a-z0-9_\- ]/, {
      message:
        'Value must be lowercase. Only `_-` special characters are allowed',
    }),
    item_category3: zodHyphenatedString,
    item_category4: zodHyphenatedString,
    item_category5: zodHyphenatedString,
    item_list_id: zodDelimitedLowerCaseString,
    item_list_name: zodString,
    item_name: zodString.regex(/[a-z0-9!?&\- ]/, {
      message:
        'Value must be lowercase. Only `!?&-` special characters are allowed',
    }),
    item_variant: zodLowerCaseString,
    quantity: zodNumber.int(),
    postage_option: zodLowerCaseString,
    recipient_type: zodLowerCaseString,
  })
  .partial()
  .extend({
    item_id: zodHyphenatedString,
    price: zodNumber,
    quantity: zodNumber.int().positive(),
  })

export const ecommerceSchema = z
  .object({
    coupon: zodLowerCaseString,
    discount: zodNumber,
    payment_type: zodLowerCaseString,
    saved_payment: zodBoolean,
    shipping: zodNumber,
    shipping_tier: zodLowerCaseString,
    shipping_type: zodLowerCaseString,
    tax: zodNumber,
    transaction_id: zodHyphenatedString,
    value: zodNumber,
    items: z.array(ecommerceItemSchema),
  })
  .partial()
  .extend({
    currency: zodCurrencyEnum,
    value: zodNumber,
  })

export const mediaDataSchema = z
  .object({
    media_duration: zodNumber,
    media_milestone: zodNumber,
    media_provider: zodLowerCaseString,
    media_status: zodString.refine(v => v.length > 0, {
      message: '`media_status` cannot be empty. ',
    }),
    media_title: zodString,
    media_type: zodLowerCaseString,
    media_url: zodString.url(),
  })
  .partial()

export const cookieConsentDataSchema = z.object({
  functional: zodBoolean,
  performance: zodBoolean,
  strictly_necessary: zodBoolean,
  targeting: zodBoolean,
})

export const marketingDataSchema = z.object({
  advertising_id_enabled: zodBoolean,
  email: zodBoolean,
  fathers_day: zodBoolean,
  general: zodBoolean,
  group_cards: zodBoolean,
  mothers_day: zodBoolean,
  notifications: zodBoolean,
  onboarding: zodBoolean,
  orders: zodBoolean,
  reminders: zodBoolean,
})

export const customFontDataSchema = z.object({
  character_set: zodLowerCaseString,
  character_count: zodLowerCaseString,
  language: zodLowerCaseString,
  pagination: zodString,
})
