import {
  createGenericSelectContentEvent,
  EventObject,
} from '@moonpig/web-core-analytics'

export const createSearchGAEvent = ({
  eventAction,
  eventLabel,
}: {
  eventAction: string
  eventLabel?: string
}) => {
  return createGenericSelectContentEvent({
    eventCategory: 'search',
    eventAction,
    eventLabel: eventLabel || 'search input',
  })
}

/* istanbul ignore next */
export const createReminderSuggestionEvent = ({
  event,
  eventAction,
  eventLabel,
  eventValue,
  index,
  numberOfResults,
  productCategory,
  searchTerm,
}: {
  event: 'search_suggestion_click' | 'search_suggestion'
  eventAction: string
  eventLabel: string
  eventValue?: number
  index?: number
  numberOfResults?: number
  productCategory?: string
  searchTerm?: string
}) => {
  return {
    event,
    results_data: {
      ...(index && { index }),
      input_type: 'facet suggestion',
      number_of_results: numberOfResults || /* istanbul ignore next */ 0,
      product_category: productCategory || '',
      results_list: searchTerm || '',
      suggestion_type: 'reminders',
    },
    event_data: {
      category: 'search',
      action: eventAction,
      label: eventLabel,
      value: eventValue,
      non_interaction: true,
    },
  } as EventObject
}
