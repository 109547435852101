export const experimentCampaigns = {
  'attach-basket-xsell-algorithm': [],
  'browse-dynamic-hero-validation': [],
  'client-side-routing': [],
  'gallery-direct-smile-impact': [],
  'loyalty-cards-filter': ['ShowCardsFilter', 'Default'],
  'loyalty-show-mp-plus-selector': [],
  'search-use-new-ia-index-v3': [],
  'shop-on-app-pop-up': [],
  'show-gift-vouchers': [],
  'xsell-algorithm': [],
  'site-xsell-algorithm': [],
  'browse-tabbed-carousels': [],
  'pa-aa-test-one': [],
  'pa-aa-test-two': [],
  'pa-aa-test-three': [],
  'browse-contentful-caching': [],
  'customer-credit-nav': [],
  'new-pa-aa-test-one': [],
  'new-pa-aa-test-two': [],
  'new-pa-aa-test-three': [],
  'customer-review-and-pay-recipient-tracking': [],
  'gallery-cta-experiment': [],
  'browse-navigation-caching': [],
  'browse-upcoming-occasions-mvt': [],
  'browse-login-tooltip': [],
  'attach-gift-x-sell-carousel-tabs': [],
  'search-dynamic-placeholder': [],
  'loyalty-mp-plus-499': [],
  'search-gift-x-sell-carousel': [],
  'attach-product-video': ['Original', 'enabled'],
  'subscriptions-sign-up-component': ['Original', 'VariantA', 'VariantB'],
  'loyalty-plus-in-basket-redesign-v2': ['Original', 'Variant'],
  'search-use-shared-ranking-v2': ['Original', 'Enabled'],
  'editor-cropping': [],
  'editor-skip-inside-left': [],
  'attach-first-name-cross-sell-header': ['Original', 'enabled'],
  'editor-sticker-promotion': ['Control', 'V1', 'V2'],
  'search-use-ai-data': ['Original', 'Enabled'],
  'attach-personalised-promotion-model': ['Original', 'enabled'],
  'customer-order-conf-redesign': ['Original', 'enabled'],
  'search-use-ai-generated-data': ['Original', 'Enabled'],
  'mnpg-price-trial': ['Original', 'trial_a', 'trial_b', 'trial_c'],
  'customer-google-one-tap': ['Original', 'enabled'],
  'search-quick-filter-toggles': ['Original', 'Enabled'],
  'checkout-basket-promo-book': ['Original', 'enabled'],
  'search-use-dropdown-filters': ['Original', 'Enabled'],
  'search-ranking-top-100': ['Original', 'Enabled'],
  'explore-editable-card-content': ['Original', 'enabled'],
  'search-use-vector-boosted-ranking': ['Original', 'Enabled'],
  'explore-upsell-redesign': ['Original', 'enabled'],
  'search-mixed-category-nbas': ['Original', 'Enabled'],
  'search-new-gallery-ux-v2': ['Original', 'Enabled'],
  'explore-nav-redesign-v2': ['Original', 'enabled'],
  'search-use-department-suggestions': ['Original', 'Enabled'],
  'search-trending-searches': ['Original', 'Enabled'],
  'explore-upsell-simplification': ['Original', 'enabled'],
  'explore-show-klarna': ['Original', 'enabled'],
  'explore-mission-buttons-imagery': [
    'Original',
    'illustration-imagery',
    'square-imagery',
  ],
  'search-use-competitive-ranking': ['Original', 'Enabled'],
  'search-use-newness-v2': ['Original', 'Enabled'],
  'explore-basket-gift-cards': ['Original', 'enabled'],
}

export const getExperimentCampaigns = () => Object.keys(experimentCampaigns)
