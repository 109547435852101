import { offlineInit } from '@eppo/js-client-sdk'
import { EppoConfiguration } from '../../types'
import { logAssignment } from '../trackExperiment/trackExperiment'

type CreateEppoClient = {
  flagsConfiguration: EppoConfiguration
}

let cachedEppoConfiguration: EppoConfiguration = null

export const createEppoClient = ({ flagsConfiguration }: CreateEppoClient) => {
  if (flagsConfiguration) {
    cachedEppoConfiguration = flagsConfiguration
  }

  if (!cachedEppoConfiguration) return null

  return offlineInit({
    flagsConfiguration: cachedEppoConfiguration,
    isObfuscated: false,
    assignmentLogger: { logAssignment },
  })
}
