import { EppoClient } from '@eppo/js-client-sdk-common'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'
import { EppoConfiguration } from '../../types'
import { setupGetAllExperimentVariants } from '../setupGetAllExperimentVariants/setupGetAllExperimentVariants'
import { setupGetExperimentVariant } from '../setupGetExperimentVariant/setupGetExperimentVariant'
import { parse } from '../../utils/parse-params/parse-params'
import { createEppoClient } from '../../browser'

type SetupEppo = {
  sessionId: string
  eppoClientPromise: Promise<EppoClient | null>
  defaultAttributes: DefaultSubjectAttributes
  overrideParams: Array<string>
}

export const setupEppo = async ({
  sessionId,
  eppoClientPromise,
  defaultAttributes,
  overrideParams,
}: SetupEppo) => {
  const serverSideClient = await eppoClientPromise

  const eppoConfiguration =
    serverSideClient?.getFlagConfigurations() as EppoConfiguration

  const clientSideClient = createEppoClient({
    flagsConfiguration: eppoConfiguration,
  })

  const overrides = parse(overrideParams)

  const {
    getExperimentBoolean,
    getExperimentInteger,
    getExperimentJson,
    getExperimentNumeric,
    getExperimentString,
    trackedServerExperiments,
  } = setupGetExperimentVariant({
    client: clientSideClient,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  const getAllExperimentVariants = setupGetAllExperimentVariants({
    client: clientSideClient,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  return {
    eppoConfiguration,
    getExperimentBoolean,
    getExperimentInteger,
    getExperimentJson,
    getExperimentNumeric,
    getExperimentString,
    getAllExperimentVariants,
    trackedServerExperiments,
  }
}
