import { IAssignmentEvent } from '@eppo/js-client-sdk-common'
import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import { isServer } from '@moonpig/web-core-utils'

export const logAssignment = (assignment: IAssignmentEvent) => {
  if (!assignment.experiment) return null
  if (!assignment.subjectAttributes.shouldLog) return null
  if (!assignment.variation || isServer) return null

  trackExperiment({
    featureFlag: assignment.featureFlag,
    experiment: assignment.experiment,
    variation: assignment.variation,
  })
}

export const trackExperiments = (
  experiments: Record<string, string | boolean | number | object>,
) => {
  Object.entries(experiments).forEach(([experiment, variation]) => {
    trackExperiment({ featureFlag: '', experiment, variation })
  })
}

const trackExperiment = ({
  featureFlag,
  experiment,
  variation,
}: {
  featureFlag: string
  experiment: string
  variation: string | boolean | number | object
}) => {
  const variationString =
    typeof variation === 'object' ? JSON.stringify(variation) : `${variation}`

  const useOldLogic = experiment.indexOf('payments-on-review') > -1
  const sanitisedFeatureFlag = useOldLogic
    ? sanitizeStrings(featureFlag)
    : experiment

  const experimentEvent = buildExperimentEvent({
    sanitisedFeatureFlag,
    variation: variationString,
  })
  if (!isExperimentAlreadyTracked(experimentEvent)) {
    trackGAEvent(experimentEvent)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isExperimentAlreadyTracked = (experimentEvent: Record<string, any>) => {
  if (!window.dataLayer) {
    return false
  }

  return window.dataLayer
    ?.filter(event => event.event === 'abtest')
    .some(
      event =>
        event.event_data.action === experimentEvent.event_data.action &&
        event.event_data.label === experimentEvent.event_data.label,
    )
}

const buildExperimentEvent = ({
  sanitisedFeatureFlag,
  variation,
}: {
  sanitisedFeatureFlag: string
  variation: string
}) => {
  const sanitizedVariation = sanitizeStrings(variation)
  const variationLabel =
    sanitizedVariation === 'not bucketed'
      ? 'no cohort assigned'
      : sanitizedVariation
  return {
    event: 'abtest',
    event_data: {
      category: 'ab test',
      action: sanitisedFeatureFlag,
      label: `${variationLabel} | exposure`,
      non_interaction: true,
      value: undefined,
    },
  }
}
